import ease from './ease'

class PageAnchors {
  private navigationToggleEl: HTMLButtonElement
  private headerEl: HTMLDivElement
  private heroEl: HTMLDivElement
  private sectionLinks: HTMLAnchorElement[]
  private sectionEls: HTMLDivElement[]
  private heroObserver
  private sectionObserver

  public subscribe() {
    this.navigationToggleEl = document.querySelector('.Navigation-toggle')
    this.headerEl = document.querySelector('.Section-header')
    this.heroEl = document.querySelector('.Hero')
    this.sectionLinks = Array.from(document.querySelectorAll('[data-page-anchor]'))
    this.sectionEls = Array.from(document.querySelectorAll('.subpage'))
    const headerHeight = this.navigationToggleEl.offsetHeight
    const threshold = []
    for (let i = 0; i <= 1.0; i += 0.01) {
      threshold.push(i)
    }

    if (this.headerEl && this.heroEl) {
      if (!!window.IntersectionObserver) {
        this.heroObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              this.headerEl.classList[
                entry.intersectionRect.height > headerHeight / 2 + 18 ? 'remove' : 'add'
              ]('is-sticky')
              // this.headerEl.classList[entry.isIntersecting ? 'remove' : 'add']('is-sticky')
            })
          },
          {
            threshold
          }
        )
        this.heroObserver.observe(this.heroEl)

        const entryValues = {}
        this.sectionObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              const percent =
                (100 / entry.boundingClientRect.height) * entry.intersectionRect.height
              const index = Number(entry.target.dataset.index)
              entryValues[entry.target.id] = { index, percent }
            })

            let largestPercent = 0
            let index = 0
            Object.entries(entryValues).forEach(([id, data]) => {
              if (data.percent > largestPercent) {
                largestPercent = data.percent
                index = data.index
              }
            })

            const activeLinkEl = this.headerEl.querySelector('.is-active')
            if (activeLinkEl) {
              activeLinkEl.classList.remove('is-active')
            }
            this.sectionLinks[index].classList.add('is-active')
          },
          {
            threshold,
            rootMargin: '80px 0px 0px 0px'
          }
        )
        this.sectionEls.forEach((section) => {
          this.sectionObserver.observe(section)
        })
      }
    }

    if (this.headerEl) {
      this.headerEl.addEventListener('click', this.clickHandler, false)
    }
  }

  public unsubscribe() {
    if (this.headerEl) {
      this.headerEl.removeEventListener('click', this.clickHandler, false)
      this.headerEl.classList.remove('is-sticky')
    }

    if (!!window.IntersectionObserver && !!this.heroObserver) {
      this.heroObserver.unobserve(this.heroEl)
      this.sectionEls.forEach((section) => {
        this.sectionObserver.unobserve(section)
      })
    }
    this.heroEl = null
  }

  private clickHandler = (e: MouseEvent) => {
    const el = <HTMLLinkElement>e.target

    if (el.tagName.toLowerCase() === 'a') {
      const targetId = el.getAttribute('href')
      if (targetId.includes('#')) {
        const targetEl = document.querySelector(targetId)
        if (targetEl) {
          e.preventDefault()
          const targetPos = targetEl.getBoundingClientRect().top + window.scrollY

          ease({
            startValue: window.scrollY,
            endValue: targetPos,
            durationMs: 400,
            onStep: (value) => window.scroll(0, value - 80)
          })
        }
      }
    } else if (el.classList.contains('.Section-title')) {
      const targetPos = 0
      ease({
        startValue: window.scrollY,
        endValue: targetPos,
        durationMs: 400,
        onStep: (value) => window.scroll(0, value)
      })
    }
  }
}

export default PageAnchors
