import ease from './ease'

class ImpactFacts {
  private activeIndex: number = -1
  private factEls: HTMLElement[]
  private timeout: number

  public subscribe() {
    this.factEls = Array.from(document.querySelectorAll('.ImpactFact'))
    this.timeout = setTimeout(this.showFact, 1000)
  }

  public unsubscribe() {
    clearTimeout(this.timeout)
  }

  private getNumberValue(el) {
    const numberEl = el.querySelector('.ImpactFact-number')
    const value = parseInt(numberEl.dataset.number, 10)
    return value
  }

  private showFact = () => {
    let startValue = 0
    const currentFactEl: HTMLElement = this.factEls[this.activeIndex]
    if (!!currentFactEl) {
      currentFactEl.classList.remove('is-active')
      startValue = this.getNumberValue(currentFactEl)
    }

    if (++this.activeIndex >= this.factEls.length) {
      this.activeIndex = 0
    }

    const nextFactEl: HTMLElement = this.factEls[this.activeIndex]
    if (!!nextFactEl) {
      const endValue = this.getNumberValue(nextFactEl)
      const numberEl = nextFactEl.querySelector('.ImpactFact-number')
      const textEl = nextFactEl.querySelector('.ImpactFact-text')
      nextFactEl.classList.add('is-active')

      ease({
        startValue,
        endValue,
        durationMs: 600,
        onStep: (value) => {
          const formattedNumber = Intl.NumberFormat('da-DK').format(parseInt(value, 10))
          numberEl.textContent = formattedNumber
        },
        onComplete: () => {
          textEl.classList.add('is-active')

          // Only animate when there is more than one fact.
          if (this.factEls.length > 1) {
            this.timeout = setTimeout(this.hideFact, 4000)
          }
        }
      })
    }
  }

  private hideFact = () => {
    const factEl: HTMLElement = this.factEls[this.activeIndex]
    if (!!factEl) {
      const textEl = factEl.querySelector('.ImpactFact-text')
      textEl.classList.remove('is-active')
      this.timeout = setTimeout(this.showFact, 1000)
    }
  }
}

export default ImpactFacts
