class Expander {
  private triggerEls: HTMLButtonElement[]

  public subscribe() {
    this.triggerEls = Array.from(document.querySelectorAll<HTMLButtonElement>('.Expander-trigger'))

    if (this.triggerEls.length) {
      this.triggerEls.forEach((trigger: HTMLButtonElement) => {
        trigger.addEventListener('click', this.expand, false)
      })
    }
  }

  public unsubscribe() {
    if (this.triggerEls.length) {
      this.triggerEls.forEach((trigger: HTMLButtonElement) => {
        trigger.removeEventListener('click', this.expand, false)
      })
    }
  }

  private expand = (e: MouseEvent) => {
    const trigger = <HTMLButtonElement>e.currentTarget
    const targetId = trigger.dataset.target
    if (targetId == null) {
      return
    }

    const target = document.getElementById(targetId)
    if (target == null) {
      return
    }

    target.classList.add('is-expanded')
    trigger.classList.add('is-hidden')
  }
}

export default Expander
