import { gsap } from 'gsap'

export default class Services {
  private wrapper: HTMLElement | null
  private mediaInner: HTMLElement | null
  private linkEls: HTMLAnchorElement[]
  private linkListEl: HTMLDivElement
  private mediaEls: HTMLDivElement[]
  private currentMediaNum = 0
  private intervalId: number

  public subscribe() {
    this.wrapper = document.querySelector('.Services')
    if (this.wrapper == null) {
      return
    }
    this.mediaInner = document.querySelector('.Services-media')
    this.linkListEl = this.wrapper.querySelector('.Services-link-list')
    this.mediaEls = this.mediaInner.querySelectorAll('.Media')
    this.linkEls = Array.from(document.querySelectorAll<HTMLAnchorElement>('.Services-link'))

    this.linkListEl.addEventListener('mouseenter', this.listEnterHandler, false)
    this.linkListEl.addEventListener('mouseleave', this.listLeaveHandler, false)

    if (this.linkEls.length) {
      this.linkEls.forEach((el) => {
        el.addEventListener('mouseenter', this.linkEnterHandler, false)
      })
    }

    gsap.set(this.mediaEls, {
      autoAlpha: 0
    })
    this.gotoMedia(this.currentMediaNum)
    this.startSlideShow()
  }

  public unsubscribe() {
    if (this.linkListEl != null) {
      this.linkListEl.removeEventListener('mouseenter', this.listEnterHandler, false)
      this.linkListEl.removeEventListener('mouseleave', this.listLeaveHandler, false)
    }

    if (this.linkEls.length) {
      this.linkEls.forEach((el) => {
        el.removeEventListener('mouseenter', this.linkEnterHandler, false)
      })
    }
  }

  private listEnterHandler = (e: MouseEvent) => {
    this.stopSlideShow()
  }

  private listLeaveHandler = (e: MouseEvent) => {
    this.startSlideShow()
  }

  private linkEnterHandler = (e: MouseEvent) => {
    const num = e.target.dataset.num
    this.gotoMedia(num)
  }

  private startSlideShow = () => {
    this.intervalId = setInterval(() => {
      let num = this.currentMediaNum + 1
      if (num >= this.mediaEls.length) {
        num = 0
      }
      this.gotoMedia(num)
    }, 2500)
  }

  private stopSlideShow = () => {
    clearInterval(this.intervalId)
  }

  private gotoMedia = (num: number) => {
    gsap.to(this.mediaEls[this.currentMediaNum], {
      duration: 0.5,
      overwrite: 'auto',
      autoAlpha: 0,
      ease: 'quad.inOut'
    })
    gsap.to(this.mediaEls[num], {
      duration: 0.5,
      overwrite: 'auto',
      autoAlpha: 1,
      ease: 'quad.inOut'
    })
    this.linkEls[this.currentMediaNum].classList.remove('is-active')
    this.linkEls[num].classList.add('is-active')
    this.currentMediaNum = num
  }
}
