export default class ConsentBar {
  private wrapperEl: HTMLDivElement | null

  constructor() {
    const hasAcceptedConsent =
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('consent='))
        ?.split('=')[1] === 'true'
    if (hasAcceptedConsent) {
      return
    }

    this.wrapperEl = document.querySelector('.ConsentBar')

    if (this.wrapperEl != null) {
      const btnEl = this.wrapperEl.querySelector('.ConsentBar-button')

      if (btnEl != null) {
        btnEl.addEventListener('click', this.clickHandler)
        setTimeout(this.ready, 1000)
      }
    }
  }

  private ready = () => {
    if (this.wrapperEl != null) {
      this.wrapperEl.classList.add('is-ready')
      this.wrapperEl.classList.add('is-active')
    }
  }

  private hide = () => {
    if (this.wrapperEl != null) {
      this.wrapperEl.classList.remove('is-active')
    }
  }

  private clickHandler = () => {
    document.cookie = 'consent=true; path=/; samesite=strict; expires=Fri, 31 Dec 9999 23:59:59 GMT'
    this.hide()
  }
}
