class Collapsible {
  private collapsibleEls: HTMLButtonElement[]

  public subscribe() {
    this.collapsibleEls = Array.from(document.querySelectorAll('.Collapsible'))

    if (this.collapsibleEls.length) {
      this.collapsibleEls.forEach((el: HTMLButtonElement) => {
        el.addEventListener('click', this.toggle, false)
      })
    }
  }

  public unsubscribe() {
    if (this.collapsibleEls.length) {
      this.collapsibleEls.forEach((el: HTMLButtonElement) => {
        el.removeEventListener('click', this.toggle, false)
      })
    }
  }

  private toggle = (e: MouseEvent) => {
    const btnEl = e.currentTarget as HTMLButtonElement
    const isExpanded = btnEl.getAttribute('aria-expanded') === 'true'
    btnEl.setAttribute('aria-expanded', isExpanded ? 'false' : 'true')
  }
}

export default Collapsible
