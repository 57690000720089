import imagesLoaded from 'imagesloaded'
import ScrollOut from 'scroll-out'
import Splitting from 'splitting'

import Collapsible from './collapsible'
import ConsentBar from './consent-bar'
import CrossFade from './cross-fade'
import Expander from './expander'
import Facts from './facts'
import Filters from './filters'
import GalleryGrid from './gallery-grid'
import Header from './header'
import ImpactFacts from './impact-facts'
import More from './more'
import Navigation from './navigation'
// import Newsletter from './newsletter'
import PageAnchors from './page-anchors'
import Presentation from './presentation'
import ProjectsMap from './projects-map'
import Services from './services'
import Slider from './slider'

import '/src/styles/app.scss'

interface MapWindow extends Window {
  isMapsApiLoaded: boolean
}
declare let window: MapWindow

const watchImageLoad = () => {
  const lazyImages = document.querySelectorAll('img[loading="lazy"]:not(.is-loaded)')
  const imageLoader = imagesLoaded(lazyImages)
  imageLoader.on('progress', (instance, image) => {
    image.img.classList.add('is-loaded')
  })
}
watchImageLoad()
window.watchImageLoad = watchImageLoad

const urlParams = new URLSearchParams(window.location.search)
new ConsentBar()
const collapsible = new Collapsible()
const expander = new Expander()
const facts = new Facts()
const filters = new Filters()
new Header()
const impactFacts = new ImpactFacts()
const more = new More()
const navigation = new Navigation()
// new Newsletter()
const pageAnchors = new PageAnchors()
const presentation = new Presentation()
const projectsMap = new ProjectsMap()
const services = new Services()

// On page load start.
// const onPageSend = () => {
//   collapsible.unsubscribe()
//   expander.unsubscribe()
//   facts.unsubscribe()
//   filters.unsubscribe()
//   impactFacts.unsubscribe()
//   more.unsubscribe()
//   navigation.unsubscribe()
//   pageAnchors.unsubscribe()
//   presentation.unsubscribe()
//   // projectsMap.unsubscribe()
// }

// On page load complete.
const onPageComplete = () => {
  collapsible.subscribe()
  expander.subscribe()
  facts.subscribe()
  filters.subscribe()
  impactFacts.subscribe()
  more.subscribe()
  navigation.subscribe()
  pageAnchors.subscribe()
  presentation.subscribe()
  // projectsMap.subscribe()
  services.subscribe()

  // Look for Designers pages.
  const path = document.location.href
  document.documentElement.classList[path.indexOf('/design') !== -1 ? 'add' : 'remove']('designers')

  Splitting()
  ScrollOut({
    once: true,
    targets: '[data-appear], [data-splitting]',
    threshold: 0.25
  })

  // Wrap anniversary teasers in a link.
  const anniversaryEls: HTMLDivElement[] = Array.from(
    document.querySelectorAll('.Teaser-anniversary .Teaser-linkInner')
  )
  anniversaryEls.forEach((anniversaryEl) => {
    const aEl: HTMLAnchorElement = anniversaryEl.querySelector('.Copy a')
    if (aEl) {
      const linkEl = document.createElement('a')
      linkEl.setAttribute('href', aEl.href)
      anniversaryEl.parentNode.appendChild(linkEl)
      linkEl.appendChild(anniversaryEl)
    }
  })

  const crossfadeEls: HTMLDivElement[] = Array.from(document.querySelectorAll('.Crossfade'))
  if (crossfadeEls.length) {
    crossfadeEls.forEach((crossfadeEl) => {
      new CrossFade(crossfadeEl)
    })
  }

  const galleryGridEls: HTMLDivElement[] = Array.from(document.querySelectorAll('.Masonry'))
  if (galleryGridEls.length) {
    galleryGridEls.forEach((galleryGridEl) => {
      new GalleryGrid(galleryGridEl)
    })
  }

  const sliderEls: HTMLDivElement[] = Array.from(document.querySelectorAll('.Slider'))
  if (sliderEls.length) {
    sliderEls.forEach((sliderEl) => {
      new Slider(sliderEl)
    })
  }

  if (document.querySelector('.ProjectsMap')) {
    if (window.isMapsApiLoaded) {
      // @ts-ignore
      projectsMap.init()
    } else {
      // @ts-ignore
      window.initMap = () => {
        projectsMap.init()
        window.isMapsApiLoaded = true
      }
    }
  }
}

onPageComplete()

// iOS viewport height fix.
const iOSViewportFix = () => {
  document.body.style.height = `${window.innerHeight}px`
}

window.onresize = iOSViewportFix
iOSViewportFix()

// Debug
const debug = urlParams.get('debug')
if (debug !== null) {
  document.documentElement.className = 'debug'
}

const enterEditLink = async (event) => {
  const editLink = event.currentTarget
  if (editLink == null) {
    return
  }

  editLink.removeEventListener('mouseenter', enterEditLink, false)
  const id = editLink.getAttribute('data-edit-link')
  if (id && parseInt(id)) {
    try {
      const response = await fetch(`/actions/entry-editor-links/entry-processor/cp-link?id=${id}`)
      if (response.ok) {
        // data object will look like this: : { success: true, message: URL } or : { success: false, message: error message }
        const data = await response.json()
        if (data.success && data.message) {
          const link = document.createElement('a')
          link.className = 'EditLink u-body3'
          link.setAttribute('href', data.message)
          link.innerText = 'Edit'
          editLink.appendChild(link)
        }
      }
      return null
    } catch (error) {
      console.error(error)
    }
  }
}

if (document.documentElement.classList.contains('is-authenticated')) {
  const editLinks = document.querySelectorAll('[data-edit-link]')
  editLinks.forEach((editLink) => {
    if (editLink.querySelector('.EditLink') == null) {
      editLink.addEventListener('mouseenter', enterEditLink, false)
    }
  })
}

if (import.meta.hot) {
  // Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
