class Facts {
  private toggleEls: HTMLButtonElement[]

  public subscribe() {
    this.toggleEls = Array.from(document.querySelectorAll<HTMLButtonElement>('.Facts-showAll'))

    if (this.toggleEls.length) {
      this.toggleEls.forEach((el: HTMLButtonElement) => {
        el.addEventListener('click', this.toggle, false)
      })
    }
  }

  public unsubscribe() {
    if (this.toggleEls.length) {
      this.toggleEls.forEach((el: HTMLButtonElement) => {
        el.removeEventListener('click', this.toggle, false)
      })
    }
  }

  private toggle = (e: MouseEvent) => {
    const el = <HTMLButtonElement>e.currentTarget
    el.parentElement.classList.toggle('is-expanded')
  }
}

export default Facts
