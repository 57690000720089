import ease from './ease'

class More {
  private heroEl: HTMLDivElement
  private moreEl: HTMLAnchorElement
  private observer: IntersectionObserver

  public subscribe() {
    this.moreEl = document.querySelector('.More')

    if (this.moreEl) {
      if (!!window.IntersectionObserver) {
        this.heroEl = document.querySelector('.Hero')

        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              this.moreEl.classList[entry.isIntersecting ? 'remove' : 'add']('is-active')
            })
          },
          { threshold: 0.5 }
        )
        this.observer.observe(this.heroEl)
      }

      this.moreEl.addEventListener('click', this.clickHandler, false)
    }
  }

  public unsubscribe() {
    if (this.moreEl) {
      if (!!window.IntersectionObserver) {
        this.observer.unobserve(this.heroEl)
      }
      this.moreEl.removeEventListener('click', this.clickHandler, false)
    }
  }

  private clickHandler = (e: MouseEvent) => {
    const el = <HTMLAnchorElement>e.target
    const isActive = el.classList.contains('is-active')
    const targetId = el.getAttribute('href')
    if (targetId.includes('#')) {
      const targetEl = document.querySelector(targetId)

      if (targetEl) {
        e.preventDefault()
        const targetPos = isActive ? 0 : targetEl.getBoundingClientRect().top + window.scrollY

        ease({
          startValue: window.scrollY,
          endValue: targetPos,
          durationMs: 400,
          onStep: (value) => window.scroll(0, value)
        })
      }
    }
  }
}

export default More
