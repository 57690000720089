class Navigation {
  private headerEl: HTMLDivElement | null

  constructor() {
    this.headerEl = document.querySelector('.Header')

    const toggleEl = document.querySelector<HTMLButtonElement>('.Navigation-toggle')
    if (toggleEl) {
      toggleEl.addEventListener('click', this.toggle, false)
    }
  }

  public subscribe = () => {
    /*
    const path = document.location.href
    const contentEl: HTMLDivElement = document.querySelector('.Content')

    // Update active language items.
    if (contentEl) {
      const currentSiteHandle = contentEl.dataset.site
      const langItems: HTMLLIElement[] = Array.from(this.headerEl.querySelectorAll('.Navigation-language li'))

      langItems.forEach((item) => {
        item.classList[item.dataset.site === currentSiteHandle ? 'add' : 'remove']('is-active')
      })
    }

    // Update active nav items.
    const navItems = this.headerEl.querySelectorAll('.Navigation-primary a')
    Array.from(navItems).forEach((item) => {
      item.classList[path !== '/' && path.includes(item.getAttribute('href')) ? 'add' : 'remove']('is-active')
    })
    */
  }

  public unsubscribe = () => {
    this.close()
  }

  private toggle = () => {
    this.headerEl?.classList.add('is-ready')
    const wasActive = this.headerEl.classList.contains('is-active')

    if (wasActive) {
      this.close()
    } else {
      this.open()
    }
  }

  private open() {
    this.headerEl.classList.add('is-active')
    document.addEventListener('click', this.clickOutsideHandler)
  }

  private close() {
    this.headerEl.classList.remove('is-active')
    document.removeEventListener('click', this.clickOutsideHandler)
  }

  private clickOutsideHandler = (e) => {
    let targetEl = e.target

    do {
      // This is a click inside. Do nothing, just return.
      if (targetEl == this.headerEl) {
        return
      }

      // Go up the DOM
      targetEl = targetEl.parentNode
    } while (targetEl)

    // This is a click outside.
    this.close()
  }
}

export default Navigation
