export const getSliderButtons = () => {
  // make buttons & append them inside Siema's container
  const prevBtnEl = document.createElement('button')
  const nextBtnEl = document.createElement('button')
  const prevDivEl = document.createElement('div')
  const nextDivEl = document.createElement('div')
  const prevSvgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  const nextSvgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  const prevUseEl = document.createElementNS('http://www.w3.org/2000/svg', 'use')
  const nextUseEl = document.createElementNS('http://www.w3.org/2000/svg', 'use')

  prevSvgEl.setAttribute('width', '7')
  prevSvgEl.setAttribute('height', '11')
  prevSvgEl.setAttribute('viewBox', '0 0 7 11')
  prevSvgEl.classList.add('Icon')
  prevUseEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#chevron-left')
  prevSvgEl.appendChild(prevUseEl)

  nextSvgEl.setAttribute('width', '7')
  nextSvgEl.setAttribute('height', '11')
  nextSvgEl.setAttribute('viewBox', '0 0 7 11')
  nextSvgEl.classList.add('Icon')
  nextUseEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#chevron-right')
  nextSvgEl.appendChild(nextUseEl)

  prevDivEl.textContent = 'Forrige slide'
  nextDivEl.textContent = 'Næste slide'
  nextDivEl.classList.add('u-visuallyHidden')
  prevDivEl.classList.add('u-visuallyHidden')

  prevBtnEl.setAttribute('type', 'button')
  nextBtnEl.setAttribute('type', 'button')
  prevBtnEl.classList.add('Slider-prevButton')
  nextBtnEl.classList.add('Slider-nextButton')

  prevBtnEl.appendChild(prevDivEl)
  prevBtnEl.appendChild(prevSvgEl)
  nextBtnEl.appendChild(nextDivEl)
  nextBtnEl.appendChild(nextSvgEl)

  return [prevBtnEl, nextBtnEl]

  // event handlers on buttons
  prevBtnEl.addEventListener('click', prev)
  nextBtnEl.addEventListener('click', next)

  targetEl.parentNode.appendChild(prevBtnEl)
  targetEl.parentNode.appendChild(nextBtnEl)
}
