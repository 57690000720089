// https://css-tricks.com/a-lightweight-masonry-solution/

import A11yDialog from 'a11y-dialog'
import type { SiemaOptions } from 'siema'
import Siema from 'siema'
import { getSliderButtons } from './utils'

export default class GalleryGrid {
  private siema
  private wrapperEl: HTMLDivElement
  private targetEl: HTMLDivElement
  private modal: HTMLDivElement
  private prevBtnEl: HTMLButtonElement
  private nextBtnEl: HTMLButtonElement
  private items: HTMLButtonElement[] = []
  private gap: number = 0
  private numColumns: number = 0
  private mod: number = 0
  private startIndex: number = 0

  constructor(el: HTMLDivElement) {
    this.wrapperEl = el
    this.targetEl = el.querySelector('.Masonry-items')

    this.gap = parseFloat(getComputedStyle(this.targetEl).gridRowGap)
    this.items = Array.from(this.targetEl.children)

    this.wrapperEl.addEventListener('click', this.wrapperClickHandler, false)
    document.addEventListener('resize', this.layout, { passive: true })
    this.layout()
  }

  private layout = () => {
    /* get the post relayout number of columns */
    let numColumns = getComputedStyle(this.targetEl).gridTemplateColumns.split(' ').length

    this.items.forEach((item) => {
      let newHeight = item.getBoundingClientRect().height

      if (newHeight !== +item.dataset.height) {
        item.dataset.height = newHeight
        this.mod++
      }
    })

    /* if the number of columns has changed */
    if (this.numColumns !== numColumns || this.mod) {
      /* update number of columns */
      this.numColumns = numColumns

      /* revert to initial positioning, no margin */
      this.items.forEach((c) => c.style.removeProperty('margin-top'))

      /* if we have more than one column */
      if (this.numColumns > 1) {
        this.items.slice(numColumns).forEach((item, index) => {
          const prev_fin =
              this.items[index].getBoundingClientRect().bottom /* bottom edge of item above */,
            curr_ini = item.getBoundingClientRect().top /* top edge of current item */

          item.style.marginTop = `${prev_fin + this.gap - curr_ini}px`
        })
      }

      this.mod = 0
    }
  }

  private wrapperClickHandler = (e) => {
    e.preventDefault()
    const target = e.target

    if (target.classList.contains('Masonry-item')) {
      const modalId = this.wrapperEl.dataset.modalId
      this.modal = document.getElementById(modalId)
      this.startIndex = target.dataset.num

      const dialog = new A11yDialog(this.modal)
      dialog.on('show', this.modalShowHandler)
      dialog.on('hide', this.modalHideHandler)
      dialog.show()
    }
  }

  private modalShowHandler = () => {
    const options: SiemaOptions = {
      startIndex: this.startIndex,
      selector: this.modal?.querySelector('.Masonry-slider'),
      onChange: this.updateElements
    }

    this.siema = new Siema(options)
    document.addEventListener('keydown', this.keyHandler)

    const [prevBtnEl, nextBtnEl] = getSliderButtons()
    this.prevBtnEl = prevBtnEl
    this.nextBtnEl = nextBtnEl
    this.prevBtnEl.addEventListener('click', this.prev)
    this.nextBtnEl.addEventListener('click', this.next)
    this.modal.querySelector('.Modal-content').appendChild(this.prevBtnEl)
    this.modal.querySelector('.Modal-content').appendChild(this.nextBtnEl)
  }

  private modalHideHandler = () => {
    this.siema && this.siema.destroy(true)
    document.removeEventListener('keydown', this.keyHandler)

    this.prevBtnEl.removeEventListener('click', this.prev)
    this.nextBtnEl.removeEventListener('click', this.next)
    this.prevBtnEl && this.prevBtnEl.parentNode.removeChild(this.prevBtnEl)
    this.nextBtnEl && this.nextBtnEl.parentNode.removeChild(this.nextBtnEl)
  }

  private prev = () => {
    this.siema.prev()
  }

  private next = () => {
    this.siema.next()
  }

  private keyHandler = (e) => {
    // If it's left arrow key.
    if (e.keyCode === 37) {
      this.prev()
    }
    // If it's right arrow key.
    else if (e.keyCode === 39) {
      this.next()
    }
  }

  private updateElements = () => {}
}
