import Slider from './slider'

class Presentation {
  private slider
  private wrapperEl: HTMLDivElement
  private presentationEl: HTMLDivElement

  public subscribe = () => {
    this.wrapperEl = document.querySelector('.Content.is-project')

    if (this.wrapperEl) {
      document.addEventListener('keydown', this.keydownHandler, false)
    }
  }

  public unsubscribe = () => {
    document.removeEventListener('keydown', this.keydownHandler, false)

    if (this.presentationEl) {
      this.presentationEl = null
    }

    if (this.slider) {
      this.slider.destroy()
      this.slider = null
    }

    this.wrapperEl = null
  }

  private keydownHandler = (e) => {
    // If it's the p-key.
    if (e.keyCode === 80) {
      if (!this.slider) {
        this.generateGallery()
      }
      if (this.slider) {
        this.toggleFullScreen()
      }
    }
  }

  private toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      this.presentationEl && this.presentationEl.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  private generateGallery() {
    const imageEls: HTMLImageElement[] = Array.from(this.wrapperEl.querySelectorAll('img'))
    const imagePaths = imageEls
      .map((imgEl) => {
        const srcset = imgEl.dataset.srcset
        if (srcset) {
          const srcsetParts = srcset.split(',')
          const lastSrcset = srcsetParts[srcsetParts.length - 1].trim()
          const src = lastSrcset.split(' ')[0]
          return src
        } else {
          return imgEl.src || imgEl.dataset.src
        }
      })
      .filter(
        (imgPath) =>
          !!imgPath &&
          !imgPath.includes('/markers/') &&
          (imgPath.includes(location.host) ||
            imgPath.includes('aart.') ||
            imgPath.charAt(0) === '/')
      )

    const html = `
    <div class="Presentation" data-pagination>
      <div class="Slider">
        <div class="Slider-items">
          ${imagePaths.map((imagePath) => `<img src="${imagePath}">`).join('')}
        </div>
      </div>
      <ul class="Slider-pagination"></ul>
    </div>`

    this.wrapperEl.innerHTML += html
    this.presentationEl = document.querySelector('.Presentation')

    this.slider = new Slider(this.presentationEl)
  }
}

export default Presentation
