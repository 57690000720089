import { Cluster, ClusterStats, MarkerClusterer, Renderer } from '@googlemaps/markerclusterer'

interface Location {
  id: string
  title: string
  imageUrl: string
  url: string
  lat: number
  lng: number
  isHighlighted: boolean
}

const styles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#e9e9e9' }, { lightness: 17 }]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }, { lightness: 20 }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ffffff' }, { lightness: 17 }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }, { lightness: 18 }]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }, { lightness: 16 }]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }, { lightness: 21 }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#dedede' }, { lightness: 21 }]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }]
  },
  { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#f2f2f2' }, { lightness: 19 }]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [{ color: '#fefefe' }, { lightness: 20 }]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }]
  }
]

function getMarkerPreview(marker: google.maps.Marker) {
  return `<a href="${marker.url}" style="display:block; margin-bottom: 15px; width: 180px;">
      <img style="background: #cbcbcb; display: blocK; margin-bottom: 5px; object-fit: cover;" width="180" height="99" src="${marker.imageUrl}">
      <div style="font-family: Gotham, sans-serif; font-weight: bold; line-height: 1.3">${marker.title}</div>
    </a>`
}

class CustomRenderer implements Renderer {
  public render(cluster: Cluster, stats: ClusterStats): google.maps.Marker {
    const marker = new google.maps.Marker({
      position: cluster.position,
      icon: '/assets/images/markers/m2.png',
      label: {
        text: String(cluster.count),
        color: '#fff',
        fontSize: '14px',
        fontFamily: 'Gotham, sans-serif',
        fontWeight: 'bold'
      },
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count
    })

    google.maps.event.addListener(marker, 'mouseover', () => this.mouseover(cluster))

    return marker
  }
}

class ProjectsMap {
  isOpeningCluster = false

  public init() {
    const el = document.querySelector('.ProjectsMap-inner')
    if (!el) {
      return
    }

    const map = new google.maps.Map(el, {
      zoom: 5,
      styles,
      streetViewControl: false,
      mapTypeControl: false
    })

    const bounds = new google.maps.LatLngBounds()
    const infowindow = new google.maps.InfoWindow()
    const locations: Location[] = window.projectLocations

    // Add markers to the map.
    let highlightedMarker
    const markers: google.maps.Marker[] = locations.map((location, i) => {
      const marker = new google.maps.Marker({
        icon: '/assets/images/markers/m2.png',
        imageUrl: location.imageUrl,
        title: location.title,
        label: {
          text: location.isHighlighted ? location.title : '1',
          color: location.isHighlighted ? '#000' : '#fff',
          textSize: 14,
          fontFamily: 'Gotham, sans-serif',
          fontWeight: 'bold'
        },

        map,
        position: new google.maps.LatLng(Number(location.lat), Number(location.lng)),
        url: location.url
      })

      if (location.isHighlighted) {
        highlightedMarker = marker
      }

      // Extend the bounds to include each marker's position.
      bounds.extend(marker.position)

      google.maps.event.addListener(marker, 'click', () => {
        window.location.href = marker.url
      })

      google.maps.event.addListener(marker, 'mouseover', (e) => {
        const content = getMarkerPreview(marker)
        infowindow.close() // closes previous open ifowindows
        infowindow.setContent(content)
        infowindow.open(map, marker)
      })

      return marker
    })

    if (highlightedMarker) {
      map.setZoom(13)
      map.panTo(highlightedMarker.position)
    } else {
      //now fit the map to the newly inclusive bounds
      map.fitBounds(bounds)
    }

    const renderer = new CustomRenderer()
    const onClusterClick = (e, cluster: Cluster) => {
      this.isOpeningCluster = true
      const marksInCluster = cluster.markers || []
      let content = ''
      for (let i = 0; i < marksInCluster.length; i++) {
        content += getMarkerPreview(marksInCluster[i])
      }

      const info = new google.maps.MVCObject()
      info.set('position', cluster.position)

      infowindow.close() // closes previous open ifowindows
      infowindow.setContent(content)
      infowindow.open(map, info)

      setTimeout(() => {
        this.isOpeningCluster = false
      }, 500)
    }

    const markerCluster = new MarkerClusterer({ map, markers, renderer, onClusterClick })

    renderer.mouseover = (cluster: Cluster) => {
      if (cluster.markers == null) {
        return
      }

      let content = ''
      cluster.markers.forEach((marker) => {
        content += getMarkerPreview(marker)
      })

      const info = new google.maps.MVCObject()
      info.set('position', cluster.position)

      infowindow.close() // closes previous open ifowindows
      infowindow.setContent(content)
      infowindow.open(map, info)
    }

    google.maps.event.addListener(map, 'bounds_changed', () => {
      if (!this.isOpeningCluster) {
        infowindow.close()
      }
    })

    google.maps.event.addListener(map, 'click', () => {
      infowindow.close()
    })
  }
}

export default ProjectsMap
