class Filters {
  private wrapperEl: HTMLDivElement

  public subscribe = () => {
    this.wrapperEl = document.querySelector('.Filters-wrapper') as HTMLDivElement
    if (this.wrapperEl == null) {
      return
    }

    this.wrapperEl.addEventListener('click', this.handleWrapperClick, false)
    // this.wrapperEl.querySelector<HTMLInputElement>('.Form-searchField')?.focus()
  }

  public unsubscribe = () => {
    if (this.wrapperEl == null) {
      return
    }

    this.wrapperEl.removeEventListener('click', this.handleWrapperClick, false)
  }

  private handleWrapperClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target == null) {
      return
    }
    if (target.tagName === 'BUTTON') {
      if (target.classList.contains('Toggle-filter')) {
        this.toggleFilter()
      } else if (target.classList.contains('Toggle-map')) {
        this.toggleMap()
      } else if (target.classList.contains('FilterGroup-toggle')) {
        this.toggleFilterGroup(target)
      }
    }
  }

  // Maps section.
  private toggleMap = () => {
    const isActive = this.wrapperEl.classList.contains('map-active')
    this[isActive ? 'hideMap' : 'showMap']()
  }

  private showMap = () => {
    const isActive = this.wrapperEl.classList.contains('map-active')
    if (isActive) {
      return
    }

    this.wrapperEl.classList.add('map-active')
    this.hideFilter()
  }

  private hideMap = () => {
    this.wrapperEl.classList.remove('map-active')
  }

  // Filters section.
  private toggleFilter = () => {
    const isActive = this.wrapperEl.classList.contains('filters-active')
    this[isActive ? 'hideFilter' : 'showFilter']()
  }

  private showFilter = () => {
    const isActive = this.wrapperEl.classList.contains('filters-active')
    if (isActive) {
      return
    }

    this.wrapperEl.classList.add('filters-active')
    this.hideMap()
  }

  private hideFilter = () => {
    this.wrapperEl.classList.remove('filters-active')
  }

  private toggleFilterGroup = (target) => {
    target.closest('.FilterGroup')?.classList.toggle('is-expanded')
  }
}

export default Filters
