export default class CrossFade {
  private images: HTMLImageElement[]
  private currentIndex: number = 0
  private delay = 2000
  private firstFade = true

  constructor(el: HTMLDivElement) {
    el.classList.add('is-ready')
    this.images = Array.from(el.querySelectorAll('.Crossfade-images'))
    const image = this.images[this.currentIndex]
    image.classList.add('is-active')
    this.setNextImage(image)
  }

  // Set next image in DOM to is-active (if at last image in DOM set first image)
  private setNextImage = (image: HTMLImageElement) => {
    setTimeout(
      () => {
        this.firstFade = false
        image.classList.remove('is-active')
        if (++this.currentIndex >= this.images.length) {
          this.currentIndex = 0
        }

        const nextImage = this.images[this.currentIndex]
        nextImage.classList.add('is-active')
        setTimeout(() => this.setNextImage(nextImage), this.delay)
      },
      this.firstFade ? this.delay * 1.5 : this.delay
    )
  }
}
