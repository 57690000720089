import type { SiemaOptions } from 'siema'
import Siema from 'siema'
import { getSliderButtons } from './utils'

class Slider {
  private siema
  private wrapperEl: HTMLDivElement
  private targetEl: HTMLDivElement
  private prevBtnEl: HTMLButtonElement
  private nextBtnEl: HTMLButtonElement
  private captionEls: HTMLDivElement[] = []
  private paginationEls: HTMLButtonElement[] = []
  private viewAllEl: HTMLButtonElement

  constructor(el: HTMLDivElement) {
    this.wrapperEl = el
    this.targetEl = el.querySelector('.Slider-items')
    const multiplePerPage = typeof el.dataset.multiplePerPage !== 'undefined'
    const withPagination = typeof el.dataset.pagination !== 'undefined'
    const totalItems = this.targetEl.children.length

    this.targetEl.classList.add('is-active')

    const options: SiemaOptions = {
      selector: this.targetEl,
      onChange: this.updateElements
    }

    if (multiplePerPage) {
      options.perPage = {
        480: 1, // sm
        760: 2, // md
        1020: 3, // lg
        1540: 4 // xl
      }
    }

    this.siema = new Siema(options)

    this.captionEls = Array.from(this.wrapperEl.parentNode.querySelectorAll('.Media-caption'))

    if (totalItems > 1) {
      this.addArrows()
      this.addKeyboard()
      if (withPagination) {
        this.addPagination()
      }
    }

    this.addFullscreen()
    this.bindViewAll()
    this.updateElements()
  }

  private addArrows() {
    // make buttons & append them inside Siema's container
    const [prevBtnEl, nextBtnEl] = getSliderButtons()
    this.prevBtnEl = prevBtnEl
    this.nextBtnEl = nextBtnEl

    // event handlers on buttons
    this.prevBtnEl.addEventListener('click', this.prev)
    this.nextBtnEl.addEventListener('click', this.next)

    this.targetEl.parentNode.appendChild(this.prevBtnEl)
    this.targetEl.parentNode.appendChild(this.nextBtnEl)
  }

  private addPagination() {
    let listEl: HTMLUListElement = this.wrapperEl.querySelector('.Slider-pagination')
    if (!listEl) {
      listEl = this.wrapperEl.parentNode.querySelector('.Slider-pagination')
    }

    // const listEl: HTMLUListElement = document.createElement('ul')
    // listEl.classList.add('Slider-pagination')

    if (listEl) {
      for (let i = 0; i < this.siema.innerElements.length; i++) {
        const liEl: HTMLLIElement = document.createElement('li')
        const btnEl: HTMLButtonElement = document.createElement('button')
        const divEl: HTMLDivElement = document.createElement('div')
        divEl.classList.add('u-visuallyHidden')
        divEl.textContent = i.toString()
        btnEl.classList.add('Slider-paginationButton')
        btnEl.addEventListener('click', this.goTo.bind(this, i))
        btnEl.appendChild(divEl)
        liEl.appendChild(btnEl)
        listEl.appendChild(liEl)

        this.paginationEls.push(btnEl)
      }
    }

    // this.targetEl.parentNode.appendChild(listEl)
  }

  private addKeyboard() {
    // listen for keydown event
    document.addEventListener('keydown', (e) => {
      // If it's left arrow key.
      if (e.keyCode === 37) {
        this.prev()
      }
      // If it's right arrow key.
      else if (e.keyCode === 39) {
        this.next()
      }
    })
  }

  private addFullscreen() {
    if (document.fullscreenEnabled) {
      const btnEl = this.wrapperEl.querySelector('.Gallery-fullscreen')
      if (btnEl) {
        btnEl.addEventListener('click', this.toggleFullScreen, false)
      }
    }
  }

  private bindViewAll() {
    this.viewAllEl = this.wrapperEl.querySelector('.Filter-viewAll')
    if (this.viewAllEl) {
      this.viewAllEl.addEventListener('click', this.destroy)
    }
  }

  public toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      const galleryEl = document.querySelector('.Gallery')
      galleryEl && galleryEl.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  public destroy() {
    this.siema && this.siema.destroy(true)
    this.prevBtnEl && this.prevBtnEl.parentNode.removeChild(this.prevBtnEl)
    this.nextBtnEl && this.nextBtnEl.parentNode.removeChild(this.nextBtnEl)
    this.viewAllEl && this.viewAllEl.parentNode.removeChild(this.viewAllEl)
  }

  private prev = () => {
    this.siema.prev()
  }

  private next = () => {
    this.siema.next()
  }

  private goTo(index: number) {
    this.siema.goTo(index)
    this.updateElements()
  }

  private updateElements = () => {
    const index = this.siema.currentSlide
    const total = this.siema.innerElements.length

    if (this.prevBtnEl) {
      if (!index) {
        this.prevBtnEl.setAttribute('disabled', 'disabled')
      } else {
        this.prevBtnEl.removeAttribute('disabled')
      }
    }

    if (this.nextBtnEl) {
      if (index === total - this.siema.perPage) {
        this.nextBtnEl.setAttribute('disabled', 'disabled')
      } else {
        this.nextBtnEl.removeAttribute('disabled')
      }
    }

    this.captionEls.forEach((el, i) => {
      el.classList[index === i ? 'add' : 'remove']('is-active')
    })

    this.paginationEls.forEach((el, i) => {
      el.classList[index === i ? 'add' : 'remove']('is-active')
    })
  }
}

export default Slider
