import Headroom from 'headroom.js'

export default class Header {
  constructor() {
    const headerEl = document.querySelector('.Header')
    if (headerEl != null) {
      const headroom = new Headroom(headerEl)
      headroom.init()
    }
  }
}
